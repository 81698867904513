@import url('https://fonts.googleapis.com/css2?family=Afacad:wght@400..700&display=swap');


:root {
  --color-primary: #000000;
  --color-on-primary: #FFFFFF;
  --color-background: #FFFFFF;
  --color-on-background: #000000;
  --color-product-image-background: rgba(0, 0, 0, 0.05);

  --color-primary-rgb: 0, 0, 0;
  --color-on-primary-rgb: 255, 255, 255;
  --color-background-rgb: 255, 255, 255;
  --color-on-background-rgb: 0, 0, 0;

  --color-background-brightness: 255;

  --button-corner-radius: 32px;
  --input-corner-radius: 5px;
  --image-corner-radius: 10px;

  --font-family-base: "Afacad", sans-serif;
  --font-family-heading: "Afacad", sans-serif;

  --font-weight-base: 400;
  --font-weight-base-bold: bolder;
  --font-weight-heading: 700;

  --font-style-base: normal;
  --font-style-heading: normal;

  --text-transform-base: none;
  --text-transform-heading: none;
  --logo-width: 100px;

  --image-ratio-padding: 100%;

  --letter-spacing: normal;
  --line-height-base: 1.5;
  --line-height-heading: 1.2;
  --font-size: 0px;
}
